<template>
    <div>
        <myBreadcrumb :nav="nav"></myBreadcrumb>
        <div class="h">
            <!--            <div class="types">-->
            <!--                <span>类型：</span>-->
            <!--                <el-select v-model="value" placeholder="全部">-->
            <!--                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>-->
            <!--                </el-select>-->
            <!--            </div>-->

            <!--            <div class="types">-->
            <!--                <span>排序：</span>-->
            <!--                <el-select v-model="value" placeholder="热门">-->
            <!--                    <el-option v-for="item in sort" :key="item.value" :label="item.label" :value="item.value"></el-option>-->
            <!--                </el-select>-->
            <!--            </div>-->
            <div class="right">
                <el-input class="sq-user-search" prefix-icon="el-icon-search" placeholder="请输入内容" v-model="search" clearable></el-input>
            </div>
            <!-- 要替换掉下面的选择框          ！！！！！！！！！！！！！！ -->
            <!-- <div class="menu">
                <span>类型：</span>
                <select>
                    <option>全部</option>
                </select>
            </div>
            <div class="menu">
                <span>排序：</span>
                <select>
                    <option>热门</option>
                </select>
            </div>
            <div class="box">
                <input type="text" class="input" placeholder="请输入搜索内容" /> -->
            <!--placeholder是文本框中的提示文字-->
            <!-- </div> -->
        </div>

        <div class="n">
            <div class="book" v-for="(item, index) in list" :key="index" @click="$router.push({ path: '/Details', query: { id: item.id } })">
                <img :src="item.picUrl" alt="" />
                <span>{{ item.name }}</span>
            </div>
        </div>
        <el-pagination @size-change="getBooksList" @current-change="getBooksList" :current-page.sync="page" :page-sizes="[12, 24, 36, 48]" :page-size.sync="limit" layout="prev, pager, next,jumper" class="pagination" :total="total" background></el-pagination>
    </div>
</template>

<script>
import { getBooksList } from '@/api';
export default {
    data() {
        return {
            search: null,
            page: 1,
            limit: 12,
            time: null,
            list: [],
            total: 0,
            nav: [
                {
                    title: '首页',
                    to: '/',
                },
                {
                    title: '阅览室',
                },
                {
                    title: '看书',
                },
            ],
        };
    },
    watch: {
        //    搜索节流
        search(value) {
            clearTimeout(this.time);
            this.time = setTimeout(() => {
                this.page = 1;
                this.getBooksList();
            }, 500);
        },
    },
    created() {
        this.getBooksList();
    },
    methods: {
        getBooksList() {
            let data = {
                pageNum: this.page,
                pageSize: this.limit,
                name: this.search,
                libraryId: localStorage.getItem('id'),
            };
            getBooksList(data).then((res) => {
                console.log(res);
                this.list = res.rows;
                this.total = res.total;
            });
        },
    },
};
</script>

<style lang="less" scoped>
.position {
    margin-top: 24px;

    span {
        font-size: 16px;
        line-height: 23px;

        color: #666666;
    }
}

// .h {
//     margin-top: 24px;
//     display: flex;
// }
.h {
    margin-top: 24px;
    display: flex;
    margin-bottom: 24px;

    .types {
        display: flex;
        align-items: center;
        margin-right: 24px;

        span {
            font-size: 13px;
            line-height: 19px;
        }

        .el-select {
            width: 160px;
            height: 39px;
            margin-left: 10px;

            border-radius: 4px;
        }
    }
}
.right {
    display: flex;
    align-items: center;
    //margin-left: 49%;
    width: 100%;
    justify-content: flex-end;
    ::v-deep .el-input__inner,
    ::v-deep .el-input {
        border-radius: 20px;
        width: 240px;
        height: 30px;
    }
    ::v-deep .el-input__prefix {
        margin-top: -3px;
        //i {
        //    height: 30px;
        //}
    }
}
.menu {
    width: 20%;

    span {
        size: 14px;
        height: 19px;
        color: #333333;
        margin-right: 10px;
    }
}

select {
    width: 70%;
    border: 1px solid rgba(0, 0, 0, 0.06);
    height: 39px;
    outline: none;
    padding-left: 12px;
}

.p {
    padding-right: 100px;
}

.box {
    width: 20%;
    margin-left: 39%;

    input {
        border: 1px solid rgba(237, 162, 0, 0.1);
        border-radius: 16px;
        background-color: #ffffff;
        width: 100%;
        padding: 5.5px 0 5.5px 0;
        outline: none;
    }
}

.n {
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    //margin-right: 1%;

    .book {
        display: flex;
        width: 15%;
        flex-direction: column;
        align-items: center;
        margin-right: 2%;

        img {
            width: 100%;
            height: 253.5px;
            margin-bottom: 24px;
            border-radius: 2px;
            box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.9);
        }

        span {
            font-size: 18px;
            color: #333333;
            margin-bottom: 50.5px;
        }
    }
}

.book:nth-child(6n) {
    margin-right: 0;
}
</style>
